import React from 'react'
import gracias from "../../assets/gracias.svg"

const Gracias = () => {
    return (
        <div className="bg-gracias">
            {/* <div> */}
                <img src={gracias}className="centro" />
            {/* </div> */}
        </div>
    )
}

export default Gracias
