import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Formulario from "./components/Formulario/Formulario";
import Terminos from "./components/Terminos/Terminos";
import Building from './components/Building/Building'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Gracias from "./components/Gracias/Gracias";

function App() {
  return (
    <Router>
      <div className="container-sorteo fade-in animated ">
        <div className="">
          <Switch>
            <Route exact  path="/" component = {Gracias}/>
            <Route exact path="/terminos-y-condiciones" component={Terminos} />
          </Switch>
        </div>
        <ToastContainer hideProgressBar position="top-center" />
      </div>
    </Router>
  );
}

export default App;
